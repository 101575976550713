(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/redbeemedia/assets/javascripts/video-player-live.js') >= 0) return;  svs.modules.push('/components/components/redbeemedia/assets/javascripts/video-player-live.js');
'use strict';

const LiveButton = _ref => {
  let {
    isLiveEdge,
    seekToLive
  } = _ref;
  return React.createElement("button", {
    className: "redbee-video-player-live-control vjs-control vjs-button ".concat(isLiveEdge ? 'redbee-video-player-live-edge' : ''),
    onClick: seekToLive,
    type: "button"
  }, React.createElement("span", {
    className: "vjs-icon-placeholder"
  }), React.createElement("span", {
    className: "vjs-control-text"
  }), React.createElement("div", {
    className: "redbee-video-player-live-display"
  }, React.createElement("span", {
    className: "vjs-control-text"
  }, "Str\xF6mningstyp"), "LIVE"));
};
svs.components.redbee_video_player.LiveButton = LiveButton;

 })(window);