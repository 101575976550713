(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/redbeemedia/views/video-player.js') >= 0) return;  svs.modules.push('/components/components/redbeemedia/views/video-player.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_cmps=_svs.components=_svs.components||{};
_cmps.redbeemedia=_cmps.redbeemedia||{};
_cmps.redbeemedia.templates=_cmps.redbeemedia.templates||{};
svs.components.redbeemedia.templates.video_player = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "redbeemedia-video-wrapper redbeemedia-video-wrapper-fit";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div \n    class=\"js-redbee-media-media-container "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"fitWrapper") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":43},"end":{"line":2,"column":123}}})) != null ? stack1 : "")
    + "\"\n    data-asset-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"assetId") || (depth0 != null ? lookupProperty(depth0,"assetId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"assetId","hash":{},"data":data,"loc":{"start":{"line":3,"column":19},"end":{"line":3,"column":30}}}) : helper)))
    + "\" \n    data-business-unit=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"businessUnit") || (depth0 != null ? lookupProperty(depth0,"businessUnit") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"businessUnit","hash":{},"data":data,"loc":{"start":{"line":4,"column":24},"end":{"line":4,"column":40}}}) : helper)))
    + "\"\n    data-customer=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"customerId") || (depth0 != null ? lookupProperty(depth0,"customerId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"customerId","hash":{},"data":data,"loc":{"start":{"line":5,"column":19},"end":{"line":5,"column":33}}}) : helper)))
    + "\"  \n></div>";
},"useData":true});
Handlebars.partials['components-components-redbeemedia-video_player'] = svs.components.redbeemedia.templates.video_player;
})(svs, Handlebars);


 })(window);