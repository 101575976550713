(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/redbeemedia/assets/javascripts/video-player-button.js') >= 0) return;  svs.modules.push('/components/components/redbeemedia/assets/javascripts/video-player-button.js');

'use strict';

const Button = _ref => {
  let {
    classes,
    onMouseOver,
    iconClasses,
    title,
    onClick = null
  } = _ref;
  return React.createElement("button", {
    className: "".concat(classes),
    onClick: onClick,
    onMouseOver: onMouseOver,
    title: title,
    type: "button"
  }, React.createElement("span", {
    className: "vjs-icon-placeholder ".concat(iconClasses)
  }), React.createElement("span", {
    className: "vjs-control-text"
  }, title));
};
svs.components.redbee_video_player.Button = Button;

 })(window);