(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/redbeemedia/assets/javascripts/video-player-volume.js') >= 0) return;  svs.modules.push('/components/components/redbeemedia/assets/javascripts/video-player-volume.js');
'use strict';

const {
  Button
} = svs.components.redbee_video_player;
const Volume = _ref => {
  let {
    hasSlider,
    setVolumePercentage,
    toggleVolume,
    volume,
    volumeSliderContainerRef,
    volumeSliderRef,
    volumeStrengthIndicator
  } = _ref;
  return React.createElement("div", {
    className: "vjs-volume-panel vjs-control vjs-volume-panel-horizontal ".concat(hasSlider ? '' : 'vjs-mute-toggle-only')
  }, volume && Boolean(volume) ? React.createElement(Button, {
    classes: "vjs-mute-control vjs-control vjs-button icon",
    iconClasses: "".concat(volumeStrengthIndicator(volume)),
    onClick: () => toggleVolume(false),
    title: "Ljud av"
  }) : React.createElement(Button, {
    classes: "vjs-mute-control vjs-control vjs-button icon",
    iconClasses: "icon-volyme-off",
    onClick: () => toggleVolume(true),
    title: "Ljud p\xE5"
  }), hasSlider && React.createElement("div", {
    className: "volume-container"
  }, React.createElement("input", {
    className: "js volume-hidden-slider vjs-slider-bar vjs-slider vjs-slider-horizontal",
    max: "100",
    min: "0",
    onChange: evt => setVolumePercentage(evt.target.value),
    ref: volumeSliderRef,
    type: "range"
  }), React.createElement("div", {
    className: "vjs-volume-control vjs-control vjs-volume-horizontal",
    onMouseDown: evt => {
      const percentage = evt.nativeEvent.offsetX / volumeSliderContainerRef.current.clientWidth * 100;
      setVolumePercentage(percentage);
    },
    ref: volumeSliderContainerRef
  }, React.createElement("div", {
    className: "vjs-volume-bar vjs-slider-bar vjs-slider vjs-slider-horizontal",
    role: "slider",
    tabIndex: "0"
  }, React.createElement("div", {
    className: "vjs-volume-level icon icon-marker",
    style: {
      width: "".concat(volume, "%")
    }
  }, React.createElement("span", {
    className: "vjs-control-text"
  }))))));
};
svs.components.redbee_video_player.Volume = Volume;

 })(window);