(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/redbeemedia/assets/javascripts/video-player-utils.js') >= 0) return;  svs.modules.push('/components/components/redbeemedia/assets/javascripts/video-player-utils.js');

'use strict';

const displayTime = (t, last) => t > 0 ? (t <= 9 ? "0".concat(t) : t) + (!last ? ':' : '') : last ? '00' : '00:';

const removeEmptyTime = t => {
  if (parseInt(t === null || t === void 0 ? void 0 : t.replace(':', ''), 10)) {
    return t;
  }
  return '';
};

const secondsToHms = s => {
  const sec = Number(s);
  const hours = Math.floor(sec / 3600);
  const minutes = Math.floor(sec % 3600 / 60);
  const seconds = Math.floor(sec % 3600 % 60);
  const h = removeEmptyTime(displayTime(hours));
  const m = displayTime(minutes);
  const _s = displayTime(seconds, true);
  return "".concat(h).concat(m).concat(_s);
};

const volumeStrengthIndicator = volume => {
  if (volume === 0) {
    return 'icon-volyme-off';
  } else if (volume <= 33) {
    return 'icon-volume-low';
  } else if (volume <= 66) {
    return 'icon-volume-medium';
  } else if (volume <= 100) {
    return 'icon-volyme-on';
  }
};

const secondsDate = timestamp => Math.floor(new Date(timestamp).getTime() / 1000);
svs.components.redbee_video_player.Utils = {
  secondsDate,
  volumeStrengthIndicator,
  secondsToHms,
  removeEmptyTime,
  displayTime
};

 })(window);