(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/redbeemedia/assets/javascripts/init.js') >= 0) return;  svs.modules.push('/components/components/redbeemedia/assets/javascripts/init.js');

'use strict';

svs.components.redbee_video_player.initVideo = () => {
  const rootElements = document.querySelectorAll('.js-redbee-media-media-container[data-asset-id]');
  Array.from(rootElements).forEach(rootElement => {
    const {
      Thumbnail
    } = svs.components.redbee_video_player;
    const {
      assetId,
      businessUnit,
      customer
    } = rootElement.dataset;
    delete rootElement.dataset.asset;
    ReactDOM.render(React.createElement(Thumbnail, {
      assetId: assetId,
      businessUnit: businessUnit || 'VOD',
      customer: customer || 'SvenskaSpel'
    }), rootElement);
  });
};
setTimeout(() => {
  svs.components.redbee_video_player.initVideo();
}, 500);

 })(window);