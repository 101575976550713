(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/redbeemedia/assets/javascripts/video-player-progress-bar.js') >= 0) return;  svs.modules.push('/components/components/redbeemedia/assets/javascripts/video-player-progress-bar.js');

'use strict';

const ProgressBar = _ref => {
  let {
    currentSeek,
    currentSeekPositionRef,
    currentTime,
    currentTimePosition,
    duration,
    isVOD,
    progressBarRef,
    seekTimer,
    seekTimerRef,
    seekTimerPosition,
    seekToPosition,
    setSeekTime
  } = _ref;
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "vjs-progress-control vjs-control"
  }, React.createElement("div", {
    className: "vjs-progress-holder vjs-slider vjs-slider-horizontal",
    onClick: evt => {
      const x = evt.nativeEvent.offsetX;
      const percentagePosition = x / progressBarRef.current.clientWidth;
      seekToPosition(percentagePosition, x);
    },
    onMouseMove: evt => {
      const x = evt.nativeEvent.offsetX;
      const percentagePosition = x / progressBarRef.current.clientWidth;
      setSeekTime(percentagePosition, x);
    },
    ref: progressBarRef,
    role: "slider",
    tabIndex: "0"
  }, React.createElement("div", {
    className: "vjs-load-progress",
    style: {
      width: '100%'
    }
  }, React.createElement("div", {
    style: {
      left: '100%',
      width: '0%'
    }
  })), React.createElement("div", {
    className: "vjs-mouse-display"
  }, React.createElement("div", {
    className: "vjs-time-tooltip",
    ref: seekTimerRef,
    style: {
      left: seekTimerPosition
    }
  }, seekTimer)), React.createElement("div", {
    className: "vjs-play-progress vjs-slider-bar",
    style: {
      width: "".concat(currentSeek, "%")
    }
  }, isVOD() && React.createElement("div", {
    className: "vjs-time-tooltip",
    ref: currentSeekPositionRef,
    style: {
      left: currentTimePosition
    }
  }, currentTime)))), isVOD() && React.createElement("div", {
    className: "vjs-duration-info"
  }, "".concat(currentTime, " / ").concat(duration)));
};
svs.components.redbee_video_player.ProgressBar = ProgressBar;

 })(window);