(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/redbeemedia/assets/javascripts/video-player-controller.js') >= 0) return;  svs.modules.push('/components/components/redbeemedia/assets/javascripts/video-player-controller.js');

'use strict';

const {
  useEffect,
  useRef,
  useState
} = React;
const {
  ErrorBoundary,
  GenericError
} = svs.components.errorBoundary;
const {
  Button,
  ControllerButtons,
  LiveButton,
  PlayerEvents,
  ProgressBar,
  Volume
} = svs.components.redbee_video_player;
const {
  secondsDate,
  volumeStrengthIndicator,
  secondsToHms,
  removeEmptyTime,
  displayTime
} = svs.components.redbee_video_player.Utils;
const VideoPlayerController = _ref => {
  let {
    footerButton,
    headerButton,
    headerInfo,
    remountPlayer,
    player,
    replay
  } = _ref;
  const progressBarRef = useRef(null);
  const volumeSliderRef = useRef(null);
  const volumeSliderContainerRef = useRef(null);
  const seekTimerRef = useRef(null);
  const currentSeekPositionRef = useRef(null);
  const [currentSeek, setCurrentSeek] = useState(100);
  const [currentTime, setCurrentTime] = useState('0:00');
  const [currentTimePosition, setCurrentTimePosition] = useState(null);
  const [seekTimer, setSeekTimer] = useState(null);
  const [seekTimerPosition, setSeekTimerPosition] = useState(null);
  const [duration, setDuration] = useState('0:00');
  const [displayClass, setDisplayClass] = useState(false);
  const [isLiveEdge, setIsLiveEdge] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [volume, setVolume] = useState(100);
  const [previousVolume, setPreviousVolume] = useState(100);

  const isSafari = svs.core.detect.browser.safari();

  const isLive = () => player.isLive();
  const isPictureInPictureSupported = () => player.isPictureInPictureSupported();
  const play = () => {
    player.play();
  };
  const hasNotEnded = () => player.getState().playbackState !== 'ended';
  const playerSetVolume = value => player.setVolume({
    percentage: value
  });
  const pause = () => {
    player.pause();
  };
  const seekTo = change => player.seekTo(change);
  const seekToUTC = ms => player.seekToUTC(ms);
  const setMuted = bool => player.setMuted(bool);
  const toggleFullscreen = () => player.toggleFullscreen();
  const togglePictureInPicture = () => player.togglePictureInPicture();
  const isVOD = () => !player.isLive();
  const seekToLive = () => player.seekToLive();
  const fullscreenElement = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement;
  useEffect(() => {
    if (isVOD()) {
      setCurrentSeek(0);
    }
    const eventHandlerSetup = (event, data) => {
      var _document, _document2, _document3, _document4;
      if (event === PlayerEvents.TIME_UPDATE || event === PlayerEvents.SEEKING) {
        const newDuration = secondsToHms(playerDuration(data));
        if (duration !== newDuration) {
          setDuration(newDuration);
        }
        const currentTime = secondsToHms(isVOD() ? data.currentTime : secondsDate(data.utcCurrentTime - data.utcSeekable.start));
        setCurrentTime(currentTime);
        if (isVOD()) {
          setCurrentSeek(data.currentTime / data.duration * 100);
        } else {
          setCurrentSeek((data.currentTime - data.seekable.start) / (data.duration - data.seekable.start) * 100);
        }
      }
      switch (data === null || data === void 0 ? void 0 : data.playbackState) {
        case 'playing':
          setIsPlaying(true);
          break;
        case 'paused':
          setIsPlaying(false);
          break;
        case 'ended':
          setIsPlaying(false);
          if (fullscreenElement) {
            setIsFullscreen(false);
          }
          if (fullscreenElement && (_document = document) !== null && _document !== void 0 && _document.exitFullscreen) {
            document.exitFullscreen();
          } else if (fullscreenElement && (_document2 = document) !== null && _document2 !== void 0 && _document2.webkitExitFullscreen) {
            document.webkitExitFullscreen();
          } else if (fullscreenElement && (_document3 = document) !== null && _document3 !== void 0 && _document3.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          } else if (fullscreenElement && (_document4 = document) !== null && _document4 !== void 0 && _document4.msExitFullscreen) {
            document.msExitFullscreen();
          }
          break;
        default:
          break;
      }
      if (player.getState().isAtLiveEdge) {
        setIsLiveEdge(true);
      } else {
        setIsLiveEdge(false);
      }
    };
    if (isFullscreen === false && player.getState().playbackState !== 'ended' && fullscreenElement) {
      setIsFullscreen(true);
    }
    if (isFullscreen === true && !fullscreenElement) {
      setIsFullscreen(false);
    }

    player.onAll(_ref2 => {
      let {
        event,
        data
      } = _ref2;
      eventHandlerSetup(event, data);
    });
    return () => {
      player.removeEventListener(PlayerEvents.ALL, eventHandlerSetup);
    };
  }, [, footerButton, headerButton, headerInfo, player, fullscreenElement]);
  useEffect(() => {
    if (player.getState('ended') && replay) {
      player.destroy();
      remountPlayer();
    }
  }, [replay]);

  const playerDuration = data => {
    if (isLive()) {
      var _data$utcSeekable;
      const seconds = secondsDate((data === null || data === void 0 ? void 0 : data.utcDuration) - (data === null || data === void 0 || (_data$utcSeekable = data.utcSeekable) === null || _data$utcSeekable === void 0 ? void 0 : _data$utcSeekable.start));
      return seconds;
    }
    return data === null || data === void 0 ? void 0 : data.duration;
  };

  const toggleVolume = volumeToggle => {
    setDisplayClass(true);
    if (volumeToggle) {
      setMuted(previousVolume === 0);
      setVolume(previousVolume);
    } else {
      setMuted(true);
      setVolume(0);
    }
  };

  const setVolumePercentage = percentage => {
    const roundedPercentage = Math.round(percentage);
    setDisplayClass(true);
    playerSetVolume(roundedPercentage);
    setPreviousVolume(roundedPercentage);
    setVolume(roundedPercentage);
    setMuted(roundedPercentage === 0);
  };

  const calculateNewTime = percentagePosition => {
    const duration = player.getState().duration;
    const change = duration * percentagePosition;
    const newTimeInMS = dateFns.add(new Date(player.getState().seekable.start), {
      seconds: change
    }).getTime();
    return newTimeInMS;
  };

  const seekToPosition = (percentagePosition, x) => {
    var _currentSeekPositionR;
    let newTimeInMS;
    if (isLive()) {
      const duration = player.getState().utcSeekable.end - player.getState().utcSeekable.start;
      const change = secondsDate(duration * percentagePosition);
      newTimeInMS = dateFns.add(new Date(player.getState().utcSeekable.start), {
        seconds: change
      }).getTime();
      seekToUTC(newTimeInMS);
    } else {
      newTimeInMS = calculateNewTime(percentagePosition);
      seekTo({
        time: newTimeInMS / 1000
      });
    }
    const width = parseInt(currentSeekPositionRef === null || currentSeekPositionRef === void 0 || (_currentSeekPositionR = currentSeekPositionRef.current) === null || _currentSeekPositionR === void 0 ? void 0 : _currentSeekPositionR.clientWidth, 10) || 0;
    setCurrentTimePosition(x - width / 2);
  };

  const setSeekTime = (percentagePosition, x) => {
    var _seekTimerRef$current;
    let newTimeInMS;
    let newTimeInSeconds;
    if (isLive()) {
      const start = new Date(player.getState().utcSeekable.start).getTime();
      const end = new Date(player.getState().utcSeekable.end).getTime();
      const diffInSeconds = (end - start) / 1000 * percentagePosition;
      const newDate = dateFns.add(new Date(start), {
        seconds: diffInSeconds
      });
      const diff = dateFns.intervalToDuration({
        start: newDate,
        end
      });
      newTimeInSeconds = "-".concat(removeEmptyTime(displayTime(String(diff.hours)))).concat(displayTime(diff.minutes)).concat(displayTime(diff.seconds, true));
    } else {
      newTimeInMS = calculateNewTime(percentagePosition);
      newTimeInSeconds = secondsToHms(newTimeInMS / 1000);
    }
    const width = parseInt(seekTimerRef === null || seekTimerRef === void 0 || (_seekTimerRef$current = seekTimerRef.current) === null || _seekTimerRef$current === void 0 ? void 0 : _seekTimerRef$current.clientWidth, 10) || 0;
    setSeekTimerPosition(x - width / 2);
    setSeekTimer(newTimeInSeconds);
  };
  const mousestart = () => {
    hasNotEnded() && setDisplayClass(true);
  };
  const mouseout = () => {
    setDisplayClass(false);
  };
  const touchstart = evt => {
    if (evt.target.className !== 'vjs-modal-dialog streamplayer-overlay') {
      return;
    }
    if (displayClass) {
      setDisplayClass(false);
    } else {
      setDisplayClass(true);
    }
  };
  const touchend = evt => {
    if (evt.target.className !== 'vjs-modal-dialog streamplayer-overlay') {
      return;
    }
    evt.preventDefault();
  };
  useEffect(() => {
    const timeout = setTimeout(() => {
      setDisplayClass(false);
    }, 3000);
    return () => {
      clearTimeout(timeout);
    };
  }, [displayClass]);
  return React.createElement("div", {
    className: "video-player-controller ".concat(displayClass ? 'video-player-controller-display' : 'vjs-user-inactive', " ").concat(isFullscreen && 'vjs-fullscreen'),
    onMouseLeave: mouseout,
    onMouseMove: mousestart,
    onMouseOver: mousestart,
    onTouchEnd: touchend,
    onTouchStart: touchstart
  }, React.createElement(ErrorBoundary, {
    renderOnError: GenericError
  }, React.createElement("div", {
    className: "vjs-modal-dialog streamplayer-overlay"
  }), React.createElement("div", {
    className: "vjs-control-bar"
  }, isLive() && React.createElement(LiveButton, {
    isLiveEdge: isLiveEdge,
    seekToLive: seekToLive
  }), React.createElement(Volume, {
    hasSlider: !isSafari,
    setVolumePercentage: setVolumePercentage,
    toggleVolume: toggleVolume,
    volume: volume,
    volumeSliderContainerRef: volumeSliderContainerRef,
    volumeSliderRef: volumeSliderRef,
    volumeStrengthIndicator: volumeStrengthIndicator
  }), React.createElement(ProgressBar, {
    currentSeek: currentSeek,
    currentSeekPositionRef: currentSeekPositionRef,
    currentTime: currentTime,
    currentTimePosition: currentTimePosition,
    duration: duration,
    isVOD: isVOD,
    progressBarRef: progressBarRef,
    seekTimer: seekTimer,
    seekTimerPosition: seekTimerPosition,
    seekTimerRef: seekTimerRef,
    seekToPosition: seekToPosition,
    setSeekTime: setSeekTime
  }), footerButton && React.createElement(Button, {
    classes: "icon vjs-control vjs-button vjs-footer-button",
    iconClasses: footerButton.icon,
    onClick: () => footerButton.customOnClick()
  }), isPictureInPictureSupported() && React.createElement(Button, {
    classes: "vjs-pip-control vjs-control vjs-button icon",
    iconClasses: "icon-picture-in-picture",
    onClick: togglePictureInPicture,
    title: "Picture in picture"
  }), React.createElement(Button, {
    classes: "vjs-fullscreen-control vjs-control vjs-button icon",
    iconClasses: "icon-maximize",
    onClick: toggleFullscreen,
    title: "Fullsk\xE4rm"
  })), React.createElement(ControllerButtons, {
    headerButton: headerButton,
    headerInfo: headerInfo,
    isPlaying: isPlaying,
    isVOD: isVOD,
    pause: pause,
    play: play,
    player: player,
    seekTo: seekTo
  })));
};
svs.components.redbee_video_player.VideoPlayerController = VideoPlayerController;

 })(window);