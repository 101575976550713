(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/redbeemedia/assets/javascripts/video-player-container.js') >= 0) return;  svs.modules.push('/components/components/redbeemedia/assets/javascripts/video-player-container.js');

'use strict';

const {
  useEffect,
  useRef,
  useState
} = React;
const {
  PlayerEvents,
  VideoPlayerController
} = svs.components.redbee_video_player;
const {
  Loader
} = svs.components.ReactLoader;
const logger = new svs.core.log.Logger('components:redbee-media');
const VideoPlayerContainer = _ref => {
  let {
    assetId,
    autoplay = false,
    remountPlayer,
    businessunit = 'RacingOnline',
    footerButton,
    headerButton,
    headerInfo,
    noTracking,
    onVideoEnded,
    onVideoStarted,
    posterUrl,
    replay,
    sessionToken,
    useLowLatency
  } = _ref;
  const videoNodeRef = useRef();
  const playerRef = useRef();
  const [player, setPlayer] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const preferredFormats = supportsDash => supportsDash ? ['dash', 'hls'] : ['hls'];
  useEffect(() => {
    var _svs$components$redbe;
    const container = videoNodeRef.current;
    if (container.querySelectorAll('.redbee-player-container').length > 0) {
      return;
    }
    const playerOptions = {
      assetId,
      autoplay,
      customer: 'SvenskaSpel',
      disableCast: true,
      exposureBaseUrl: ((_svs$components$redbe = svs.components.redbee_video_player.data) === null || _svs$components$redbe === void 0 ? void 0 : _svs$components$redbe.host) || 'https://exposureapi.emp.ebsd.ericsson.net',
      businessUnit: businessunit,
      controls: false,
      disablePictureInPicture: false,
      noTracking,
      preferredFormats: preferredFormats(useLowLatency),
      sessionToken,
      poster: posterUrl,
      wrapperElement: videoNodeRef.current
    };
    const _player = new redBeeMedia.RedBeePlayer({
      player: playerOptions,
      skin: {
        disabled: true
      }
    });
    setShowLoader(true);
    _player.once(PlayerEvents.LOADED, () => {
      playerRef.current = _player;
      setShowLoader(false);
      _player.on(PlayerEvents.START, () => {
        if (!noTracking) {
          const assetTitle = _player.getAssetInfo().getTitle();
          const label = assetTitle === 'unspecified' ? assetId : assetTitle;
          svs.components.analytics.trackEvent({
            action: 'Start',
            category: 'Video',
            opt_label: label,
            opt_value: 1
          });
        }
        onVideoStarted && onVideoStarted();
      });
      _player.on(PlayerEvents.ENDED, () => {
        onVideoEnded && onVideoEnded();
      });
      setPlayer(_player);
    });
    _player.on(PlayerEvents.ERROR, error => {
      logger.info('Error when playing video:', error);
    });
    _player.load(assetId);
    return () => {
      var _playerRef$current;
      setPlayer(undefined);
      if (playerRef !== null && playerRef !== void 0 && (_playerRef$current = playerRef.current) !== null && _playerRef$current !== void 0 && (_playerRef$current = _playerRef$current.core) !== null && _playerRef$current !== void 0 && _playerRef$current.destroy) {
        playerRef.current.core.destroy();
      }
    };
  }, [assetId]);
  return React.createElement("div", {
    "data-vjs-player": true
  }, React.createElement("div", {
    className: "video-js-redbee vjs-16-9",
    id: "redbee-media-wrapper",
    ref: videoNodeRef
  }, showLoader ? React.createElement("div", {
    className: "redbeemedia-video-loader-wrapper",
    style: {
      height: '100%',
      width: '100%',
      position: 'absolute',
      marginTop: '-56.25%',
      background: 'rgba(0,0,0,0.45)'
    }
  }, React.createElement("div", {
    className: "redbeemedia-video-loader",
    style: {
      marginTop: '23%'
    }
  }, React.createElement(Loader, {
    size: 300
  }))) : null, player ? React.createElement(VideoPlayerController, {
    footerButton: footerButton,
    headerButton: headerButton,
    headerInfo: headerInfo,
    player: player,
    remountPlayer: remountPlayer,
    replay: replay
  }) : null));
};
svs.components.redbee_video_player.VideoPlayerContainer = VideoPlayerContainer;

 })(window);