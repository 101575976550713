(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/redbeemedia/assets/javascripts/video-player-controller-buttons.js') >= 0) return;  svs.modules.push('/components/components/redbeemedia/assets/javascripts/video-player-controller-buttons.js');

'use strict';

const {
  Button
} = svs.components.redbee_video_player;
const ControllerButtons = _ref => {
  let {
    pause,
    play,
    isPlaying,
    seekTo,
    player,
    isVOD,
    headerButton,
    headerInfo
  } = _ref;
  return React.createElement(React.Fragment, null, isPlaying ? React.createElement(Button, {
    classes: "vjs-control vjs-button big-pause-btn vjs-icon-pause icon",
    iconClasses: "icon-rectangle-pause",
    onClick: pause,
    title: "Paus"
  }) : React.createElement(Button, {
    classes: "vjs-big-play-button icon",
    iconClasses: "icon-play",
    onClick: play,
    title: "Spela video"
  }), React.createElement(Button, {
    classes: "rewind-btn icon icon-rewind vjs-control vjs-button",
    onClick: () => {
      seekTo({
        change: -15
      });
    },
    title: "F\xF6reg\xE5ende"
  }), React.createElement(Button, {
    classes: "forward-btn icon icon-fast-forward vjs-control vjs-button",
    onClick: () => seekTo({
      change: 15
    }),
    title: "Fram\xE5t"
  }), isVOD() && React.createElement(React.Fragment, null, React.createElement("button", {
    className: "vjs-control vjs-button icon icon-close close-video-btn",
    onClick: () => headerButton.customOnClick(player),
    title: "St\xE4ng",
    type: "button"
  }, React.createElement("span", {
    className: "vjs-icon-placeholder"
  }), React.createElement("span", {
    className: "vjs-control-text"
  }, "St\xE4ng")), React.createElement("div", {
    className: "vjs-title-bar"
  }, headerInfo)));
};
svs.components.redbee_video_player.ControllerButtons = ControllerButtons;

 })(window);