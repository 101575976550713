(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/redbeemedia/assets/javascripts/thumbnail.js') >= 0) return;  svs.modules.push('/components/components/redbeemedia/assets/javascripts/thumbnail.js');
'use strict';

const Icon = svs.ui.ReactIcon;
const {
  useState,
  useEffect,
  useRef
} = React;
const {
  Logger
} = svs.core.log;
const logger = new Logger('redbeemedia:thumbnail');
const {
  VideoPlayerContainer
} = svs.components.redbee_video_player;
const {
  ExposureService
} = redBeeMedia;

const getThumbnailFromResult = result => {
  try {
    if (!result || !result.localized || !Array.isArray(result.localized[0].images)) {
      throw new Error('Invalid input or structure');
    }
    const thumbnailImage = result.localized[0].images.find(image => image.type === 'thumbnail');
    return thumbnailImage ? thumbnailImage.url : '';
  } catch (error) {
    logger.error('Error in getThumbnailFromResult:', error);
    return '';
  }
};
const Thumbnail = _ref => {
  var _svs$components$redbe;
  let {
    customer,
    businessUnit,
    assetId
  } = _ref;
  const [thumbnailSrc, setTumbnailSrc] = useState('/images/components/redbeemedia/default-thumbnail.png');
  const [renderVideoContainer, setRenderVideoContainer] = useState(false);
  const [sessionToken, setSessionToken] = useState();
  const [videoEnded, setVideoEnded] = useState(false);
  const thumbnailRef = useRef();
  const redBeeHost = (_svs$components$redbe = svs.components.redbeemedia.data) === null || _svs$components$redbe === void 0 ? void 0 : _svs$components$redbe.host;
  const exposureService = new ExposureService({
    customer,
    businessUnit,
    exposureBaseUrl: redBeeHost || 'https://exposureapi.emp.ebsd.ericsson.net'
  });
  const handleVideoEnded = () => {
    setVideoEnded(true);
    setRenderVideoContainer(false);
  };
  useEffect(() => {
    const fetchAnonymousSessionToken = async () => {
      const {
        sessionToken: redBeeSessionToken
      } = await exposureService.authenticateAnonymous();
      setSessionToken(redBeeSessionToken);
    };
    fetchAnonymousSessionToken();
  }, []);
  useEffect(() => {
    const fetchThumbailSrc = async () => {
      const requestUri = "".concat(redBeeHost, "/v1/customer/").concat(customer) + "/businessunit/".concat(businessUnit, "/content/asset/").concat(assetId) + "?fieldSet=ALL&onlyPublished=true&includeUserData=false";
      try {
        const response = await fetch(requestUri, {
          method: 'GET'
        });
        const result = await response.json();
        if (response.status === 200) {
          const url = getThumbnailFromResult(result);
          setTumbnailSrc(url);
        }
      } catch (e) {
        logger.error("Det gick inte att H\xE4mta video tumnagel: ".concat(e.toString()));
      }
    };
    fetchThumbailSrc();
  }, [assetId, businessUnit, customer, redBeeHost]);
  const handleClick = () => {
    setRenderVideoContainer(true);
  };
  return React.createElement("div", {
    className: "redbeemedia-video"
  }, renderVideoContainer ? React.createElement(VideoPlayerContainer, {
    allowMultipleInstances: true,
    assetId: assetId,
    autoplay: true,
    businessunit: businessUnit,
    customer: customer,
    headerButton: {
      customClass: 'fold-video-btn',
      icon: 'icon-menu-up',
      customOnClick: () => setRenderVideoContainer(false)
    },
    onVideoEnded: handleVideoEnded,
    posterUrl: thumbnailSrc,
    sessionToken: sessionToken
  }) : React.createElement("div", {
    className: "redbeemedia-thumbnail"
  }, React.createElement("div", {
    className: "js-redbeemedia-thumbnail redbeemedia-thumbnail-overlay",
    onClick: handleClick,
    ref: thumbnailRef
  }, React.createElement(Icon, {
    className: "redbeemedia-thumbnail-icon",
    color: "fc-white",
    icon: videoEnded ? 'refresh' : 'play-circle',
    size: "800"
  })), React.createElement("img", {
    className: "redbeemedia-thumbnail-image",
    src: thumbnailSrc
  })));
};
svs.components.redbee_video_player.Thumbnail = Thumbnail;

 })(window);